<template>
  <div class="disable-time-invoices-list">
    <draggable-dynamic-table ref="disableTimeInvoicesList"
                             :options="options"
                             :columns="columnsLabel"
                             :data="data"
                             :in-modal="true"
                             :zero-top-radius="true"/>
  </div>
</template>

<script>
import moment from 'moment-jalaali'
import axios from 'axios'
import {getReserveTimeInvoices} from "../../../../../http/requests/sales";

export default {
  name: 'disableTimeInvoicesList',
  // metaInfo () {
  //   return {
  //     title: this.$t('users.invoices.title')
  //   }
  // },
  props: {
    timeInfo: {
      type: Object,
      default: () => { return {} }
    },
    startDate: {
      type: String,
      default: () => { return '' }
    },
    endDate: {
      type: String,
      default: () => { return '' }
    },
  },
  data () {
    return {
      requestSent: false,
      options: {
        id: 'disableTimeInvoicesListTable',
        rowKeyField: 'id'
      },
      columnsLabel: [
        {
          field: 'date',
          i18n: 'sales.seasonSchedules.disableTimes.table.header.date',
          align: 'center',
          width: 'calc(100% / 10)',
          minWidth: 130,
        },
        {
          field: 'description',
          i18n: 'sales.seasonSchedules.disableTimes.table.header.description',
          align: 'center',
          width: 'calc(100% / 10)',
          minWidth: 100,
        },
        {
          field: 'user',
          i18n: 'sales.seasonSchedules.disableTimes.table.header.user',
          align: 'center',
          width: 'calc((100% / 10) * 2)',
          minWidth: 130,
        },
        {
          field: 'rowNumber',
          i18n: 'sales.seasonSchedules.disableTimes.table.header.row',
          align: 'center',
          width: 'calc(100% / 10)',
          minWidth: 80
        }
      ],
      filters: [],
      sorts: ['order[0]=created_at,desc'],
      data: [],
      page: 1,
      total_count: null,
      loadingTimer: 0,
    }
  },
  created () {
    this.getInvoices()
  },
  methods: {
    getInvoices () {
      if (!this.requestSent) {
        this.requestSent = true

        clearTimeout(this.loadingTimer)
        this.loadingTimer = setTimeout(() => {
          if (this.data.length < this.total_count || !this.total_count) {
            if (this.$refs.disableTimeInvoicesList && this.data.length === 0) this.$refs.disableTimeInvoicesList.loadMoreStatus = 'FirstLoad'
            else if (this.$refs.disableTimeInvoicesList && this.data.length > 0) this.$refs.disableTimeInvoicesList.loadMoreStatus = 'Loading'

            this.filters = [`start_range=${this.startDate}`, `end_range=${this.endDate}`, `court_id=${this.timeInfo.court.id}`, `time_id=${this.timeInfo.time.id}`, `week_day=${this.timeInfo.date}`]

            getReserveTimeInvoices(this.page, this.filters, this.sorts).then((response) => {
              const invoices = response.data

              invoices.closed_invoices.forEach((invoice) => {
                this.data.push({
                  route: {name: 'closedInvoiceProfile', params: {id: invoice.id}, query: {}, target: '_blank'},
                  id: invoice.invoice_number || invoice.id,
                  rowNumber: this.data.length + 1,
                  user: '-',
                  description: this.$t('sales.seasonSchedules.disableTimes.labels.dynamicClosedInvoice', {id: invoice.invoice_number || invoice.id}),
                  date: {
                    value: invoice.invoice_date || '',
                    styles: 'direction: ltr;'
                  },
                  // styles: invoice.status === 2 ? 'background: #f8e0e0;' : invoice.passed ? 'background: #f0f0f0;' : ''
                })
              })

              invoices.sale_invoices.forEach((invoice) => {
                this.data.push({
                  route: {name: 'saleInvoice', params: {id: invoice.id}, query: {}, target: '_blank'},
                  id: invoice.invoice_number || invoice.id,
                  rowNumber: this.data.length + 1,
                  user: `${invoice.user.name || ''} ${invoice.user.family || ''}`,
                  description: this.$t('sales.seasonSchedules.disableTimes.labels.dynamicSaleInvoice', {id: invoice.invoice_number || invoice.id}),
                  date: {
                    value: invoice.reserved_time.date || '',
                    styles: 'direction: ltr;'
                  },
                  // styles: invoice.status === 2 ? 'background: #f8e0e0;' : invoice.passed ? 'background: #f0f0f0;' : ''
                })
              })

              // this.total_count = invoices.pagination.total
              // this.page = invoices.pagination.current_page + 1

              if (this.$refs.disableTimeInvoicesList) this.$refs.disableTimeInvoicesList.loadMoreStatus = ''

              this.requestSent = false
            }).catch((error) => {
              console.log(error)
              if (axios.isCancel(error)) {
                this.$vs.notify({
                  title: this.$t('alert.duplicateRequest.title'),
                  text: this.$t('alert.duplicateRequest.message'),
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400,
                  color: 'warning'
                })
              } else if (this.$refs.disableTimeInvoicesList) this.$refs.disableTimeInvoicesList.loadMoreStatus = 'Danger'

              this.requestSent = false
            })
          } else {
            this.requestSent = false
          }
        }, 400)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .disable-time-invoices-list {
    height: 100%;
  }
</style>
